import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Categories from '../Categories'

const CategoriesContainer = ({categories = [], title, desc = ''}) => {
    return (
        <Container className='mb-6'>
            <h2>Каталог</h2>
            <Row xs={2} sm={2} md={3} xl={4} className="gy-4 gx-2 gx-sm-4 justify-content-center">
                <Categories categories={categories} />
            </Row>
        </Container>
    )
}

export default CategoriesContainer
