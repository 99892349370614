import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MetaTags } from "react-meta-tags";
import CategoriesContainer from "../components/containers/CategoriesContainer";
import Loader from "../components/UI/Loader";
import { useGetCategoriesQuery } from "../services/RTK/home";
import NewsSlider from "../components/NewsSlider";
import BtnCallbackModal from "../components/utils/BtnCallbackModal";
import {
  BiLock,
  BiTimer,
  BiGroup,
  BiHourglass,
  BiLike,
  BiReceipt,
  BiTrip,
  BiMedal,
} from "react-icons/bi";

const Home = () => {
  const categories = useGetCategoriesQuery();

  return (
    <main className="py-0">
      {categories.isLoading && <Loader full />}
      <MetaTags>
        <title>
          {process.env.REACT_APP_SITE_NAME} — Купить чеки в Казани, купить
          документы для отчетности. Чеки с АЗС, гостиниц, автосервисов, счета
          фактуры товарные и кассовые чеки, гостиничные чеки, чеки с автомоек.
          Так жеа можно заказать акты выполненных работ и УПД (универсальные
          передаточные акты)
        </title>
        <meta
          property="title"
          content={
            process.env.REACT_APP_SITE_NAME +
            " – Купить чеки в Казани, купить документы для отчетности. Чеки с АЗС, гостиниц, автосервисов, счета фактуры товарные и кассовые чеки, гостиничные чеки, чеки с автомоек. Так жеа можно заказать акты выполненных работ и УПД (универсальные передаточные акты)"
          }
        />
        <meta
          property="og:title"
          content={
            process.env.REACT_APP_SITE_NAME +
            " — Купить чеки в Казани, купить документы для отчетности. Чеки с АЗС, гостиниц, автосервисов, счета фактуры товарные и кассовые чеки, гостиничные чеки, чеки с автомоек. Так жеа можно заказать акты выполненных работ и УПД (универсальные передаточные акты)"
          }
        />
        <meta
          name="description"
          content={process.env.REACT_APP_SITE_DESCRIPTION}
        />
        <meta
          name="og:description"
          content={process.env.REACT_APP_SITE_DESCRIPTION}
        />
      </MetaTags>

      <section className="sec-1 mb-6">
        <Container>
          <Row>
            <Col md={6}>
              <h1>КУПИТЬ ЧЕКИ В&nbsp;КАЗАНИ</h1>
              <h4>
                Мы предлагаем купить различные виды чеков в Казани, анонимно с
                доставкой
              </h4>
              <BtnCallbackModal
                btnClassName={"btn-1 mt-4"}
                btnText={"Узнать подробнее"}
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sec-advantages mb-6">
        <Container>
          <h2>Наши преимущества</h2>
          <Row xs={2} sm={2} md={3} lg={4} className="justify-content-center">
            <Col className="mb-4">
              <h5 className="advnt-1">
                <BiReceipt />
                <span>Провели 1000+ успешных сделок</span>
              </h5>
            </Col>
            <Col className="mb-4">
              <h5 className="advnt-2">
                <BiHourglass />
                <span>10 лет успешно работаем на рынке г. Казань</span>
              </h5>
            </Col>
            <Col className="mb-4">
              <h5 className="advnt-3">
                <BiTimer />
                <span>
                  Гарантия сроков - Строго соблюдаем все договорённости
                </span>
              </h5>
            </Col>
            <Col className="mb-4">
              <h5 className="advnt-4">
                <BiMedal />
                <span>
                  Оказываем широкий спектр услуг + индивидуальное выполнение
                  заказов
                </span>
              </h5>
            </Col>
            <Col className="mb-4">
              <h5 className="advnt-3">
                <BiLock />
                <span>Полная конфиденциальность</span>
              </h5>
            </Col>
            <Col className="mb-4">
              <h5 className="advnt-1">
                <BiGroup />
                <span>14 узкопрофильных специалистов готовых вам помочь</span>
              </h5>
            </Col>
            <Col className="mb-4">
              <h5 className="advnt-4">
                <BiLike />
                <span>Более 97% остаются довольны нашей работой</span>
              </h5>
            </Col>
            <Col className="mb-4">
              <h5 className="advnt-2">
                <BiTrip />
                <span>Доставка в любое место и время</span>
              </h5>
            </Col>
          </Row>
        </Container>
      </section>

      {!categories.error && categories?.data?.categories?.length > 0 && (
        <CategoriesContainer categories={categories.data.categories} />
      )}

      <NewsSlider />
    </main>
  );
};

export default Home;
