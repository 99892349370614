import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { BiXCircle } from "react-icons/bi";
import { createOrder } from "../../services/order";

const BtnCallbackModal = (props) => {
  const [showCallback, setShowCallback] = useState(false);
  const handleCloseCallback = () => setShowCallback(false);
  const handleShowCallback = () => setShowCallback(true);
  const [form, setForm] = useState({
    phone: null,
    text: null,
  });

  const onSubmit = () => {
    createOrder(form).then(() => {
      setForm({
        phone: null,
        text: null,
      });
      handleCloseCallback();
    });
  };

  return (
    <>
      <button
        type="button"
        onClick={handleShowCallback}
        className={props.btnClassName}
      >
        {props.btnText}
      </button>

      <Modal show={showCallback} onHide={handleCloseCallback}>
        <Modal.Body>
          <input
            type="text"
            placeholder="Имя"
            onChange={(e) =>
              setForm((prev) => ({ ...prev, text: e.target.value }))
            }
          />
          <input
            type="tel"
            placeholder="Телефон (+7 ХХХ ХХХ ХХ ХХ)"
            className="mt-3"
            onChange={(e) =>
              setForm((prev) => ({ ...prev, phone: e.target.value }))
            }
          />
          <button
            type="button"
            className="btn-2 w-100 mt-4"
            onClick={() => onSubmit()}
            disabled={!form.phone || !form.text}
          >
            Получить консультацию
          </button>

          <button type="button" className="close" onClick={handleCloseCallback}>
            <BiXCircle />
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BtnCallbackModal;
