import React, { useCallback, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import Modal from "react-bootstrap/Modal";
import { Badge } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Button from "./UI/Button";
import { setLoginError } from "../store/reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { apiResponseMessages } from "../config/api";
import { dispatchAlert, dispatchApiErrorAlert } from "../helpers/alert";
import useIsMobile from "../hooks/isMobile";
import {
  authActivate,
  authPasswordRecovery,
  authRegister,
} from "../services/auth";
import { login } from "../services/RTK/auth";
import ActivateAccountForm from "./forms/ActivateAccountForm";
import LoginForm from "./forms/LoginForm";
import NewPasswordForm from "./forms/NewPasswordForm";
import PasswordRecoveryForm from "./forms/PasswordRecoveryForm";
import RecoveryCodeForm from "./forms/RecoveryCodeForm";
import RegistrationForm from "./forms/RegistrationForm";
import { BiMenu, BiX, BiMobileAlt, BiUserCircle } from "react-icons/bi";

const Header = () => {
  const [isShowBurgerMenu, setIsShowBurgerMenu] = useState(false);
  const closeBurgerMenu = useCallback(() => setIsShowBurgerMenu(false), []);

  const isMobile = useIsMobile();
  const auth = useSelector((state) => state?.auth);
  const cart = useSelector((state) => state?.cart?.items);
  const favorite = useSelector((state) => state?.favorite);
  const [activeModal, setActiveModal] = useState(null);
  const [submittedData, setSubmittedData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeModal = useCallback(() => {
    setActiveModal(null);
    setSubmittedData({});
  }, []);

  const onSubmitRegistration = useCallback((data) => {
    authRegister(data)
      .then(() => {
        setActiveModal("activateAccount");
        setSubmittedData(data);
      })
      .catch((error) => dispatchApiErrorAlert(error));
  }, []);

  const onSubmitActivateAccount = useCallback((data) => {
    authActivate(data)
      .then(() => {
        dispatchAlert("success", apiResponseMessages.REGISTRATION);
        setActiveModal("login");
      })
      .catch((error) => dispatchApiErrorAlert(error));
  }, []);

  const onSubmitLogin = useCallback((data) => {
    setSubmittedData(data);
    dispatch(login(data));
  }, []);

  const onSubmitPasswordRecovery = useCallback((data, nextStep) => {
    authPasswordRecovery(data)
      .then(() => {
        setActiveModal(nextStep);
        setSubmittedData(data);

        if (data.step === 3)
          dispatchAlert("success", apiResponseMessages.RECOVERY);
      })
      .catch((error) => {
        dispatchApiErrorAlert(error);
      });
  }, []);

  const onClickAccount = useCallback(() => {
    auth.isAuth ? navigate("/admin") : setActiveModal("login");
  }, [auth.isAuth]);

  useEffect(() => {
    auth.isAuth && closeModal();
  }, [auth.isAuth]);

  useEffect(() => {
    if (activeModal === null) dispatch(setLoginError(null));
  }, [activeModal]);

  useEffect(() => {
    const loginError = auth?.loginError;

    if (loginError?.response?.data?.message?.type == "USER_NOT_ACTIVATED") {
      setActiveModal("activateAccount");
    }
  }, [auth?.loginError]);

  return (
    <>
      <header>
        <Container className="h-100">
          <Button
            type="button"
            onClick={() => setIsShowBurgerMenu((prev) => !prev)}
            className="d-block d-lg-none fs-20 main-color"
          >
            {isShowBurgerMenu ? <BiX /> : <BiMenu />}
          </Button>

          <Link to="/" className={'d-none d-lg-inline'}>
            <img className="logo" src="/images/logo2.png" alt="Чеки Казань" />
          </Link>

          <span className="d-inline d-lg-none ms-2 fs-6">
            <a href="tel:+79600449322">
              +7 (960) 044-93-22
            </a>
          </span>

          <Offcanvas
            show={isShowBurgerMenu}
            onHide={closeBurgerMenu}
            responsive="lg"
          >
            <Offcanvas.Body>
              <nav className="main-nav">
                <ul>
                  <li>
                    <NavLink to="/catalog" onClick={()=>setIsShowBurgerMenu(false)} >Каталог</NavLink>
                  </li>
                  <li>
                    <NavLink to="/blog" onClick={()=>setIsShowBurgerMenu(false)}>Статьи</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contacts" onClick={()=>setIsShowBurgerMenu(false)}>Контакты</NavLink>
                  </li>
                </ul>
              </nav>
            </Offcanvas.Body>
          </Offcanvas>

          <a href="tel:+79600449322" className="d-flex align-items-center d-none d-lg-inline">
            <BiMobileAlt className="main-color fs-15 me-2" />
            <span className="ms-2">+7 (960) 044-93-22</span>
          </a>
          <div>

          </div>

          <Button
            type="button"
            onClick={onClickAccount}
            className="d-none d-lg-flex align-items-center"
          >
            <BiUserCircle className="light-gray fs-15 " />
            <span className="d-none d-xl-inline ms-2">
              {auth.isAuth ? "Профиль" : "Войти"}
            </span>
            {auth?.user?.notificationCount > 0 && (
              <Badge pill className="ms-2" bg="danger">
                {auth.user.notificationCount}
              </Badge>
            )}
          </Button>
        </Container>
      </header>

      <Modal show={activeModal} onHide={closeModal} centered>
        <Modal.Header>
          {activeModal === "registration" && (
            <h2 className="text-center mb-0">
              Регистрация в <span className="main-color">Чеки</span>
            </h2>
          )}
          {activeModal === "activateAccount" && (
            <h2 className="text-center mb-0">Активация аккаунта</h2>
          )}
          {(activeModal === "login" || !activeModal) && (
            <h2 className="text-center mb-0">
              Вход в <span className="main-color">Чеки</span>
            </h2>
          )}
          {(activeModal === "passwordRecovery" ||
            activeModal === "recoveryCode" ||
            activeModal === "newPassword") && (
            <h2 className="text-center mb-0">Восстановление пароля</h2>
          )}
          <Button className="close" onClick={closeModal}>
            <BiX />
          </Button>
        </Modal.Header>
        <Modal.Body>
          {activeModal === "registration" && (
            <RegistrationForm
              setActiveModal={setActiveModal}
              onSubmit={onSubmitRegistration}
            />
          )}
          {activeModal === "activateAccount" && (
            <ActivateAccountForm
              setActiveModal={setActiveModal}
              onSubmit={onSubmitActivateAccount}
              login={submittedData.phone || submittedData.login || null}
            />
          )}
          {(activeModal === "login" || !activeModal) && (
            <LoginForm
              setActiveModal={setActiveModal}
              onSubmit={onSubmitLogin}
            />
          )}

          {activeModal === "passwordRecovery" && (
            <PasswordRecoveryForm
              setActiveModal={setActiveModal}
              onSubmit={onSubmitPasswordRecovery}
            />
          )}
          {activeModal === "recoveryCode" && (
            <RecoveryCodeForm
              setActiveModal={setActiveModal}
              onSubmit={onSubmitPasswordRecovery}
              phone={submittedData.phone ? submittedData.phone : null}
            />
          )}
          {activeModal === "newPassword" && (
            <NewPasswordForm
              setActiveModal={setActiveModal}
              onSubmit={onSubmitPasswordRecovery}
              phone={submittedData.phone ? submittedData.phone : null}
              recoveryKey={submittedData.key ? submittedData.key : null}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
