import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { MetaTags } from "react-meta-tags";
import NewsPreview from "../components/NewsPreview";

const Blog = () => {
  return <main>
    <MetaTags>
        <title>{process.env.REACT_APP_SITE_NAME} — Доставка и оплата заказов</title>
        <meta property="title" content={process.env.REACT_APP_SITE_NAME + ' — Доставка и оплата заказов'} />
        <meta
            property="og:title"
            content={process.env.REACT_APP_SITE_NAME + ' — Доставка и оплата заказов'}
        />
    </MetaTags>
    <section className="delivery mb-6">
        <Container>
            <h1 className="text-center">Статьи</h1>
            <Row md={3} className='g-4'>
                <Col>
                    <NewsPreview link={'/blog/article/1'} title={'Кассовые чеки'} text={'Первым видом является кассовый чек. Данный фискальный инструмент дает его обладателю уверенность в защите собственных интересов. Его можно использовать для возврата товара низкого качества или в случае других спорных ситуаций, использовать его в суде с целью предъявления претензий недобросовестным производителям.'}/>
                </Col>
                <Col>
                    <NewsPreview link={'/blog/article/2'} title={'Применение товарных чеков'} text={'Другим видом подтверждения оплаты выступает товарный чек. С его помощью потребитель также сможет защитить свои интересы, однако в отличие от кассового аналога, данный бланк требует особого внимания. Его форма может различаться, а шаблон можно заполнять вручную. Поэтому в случае неправильного оформления он просто превратиться в бесполезную вещь.'}/>
                </Col>
                <Col>
                    <NewsPreview link={'/blog/article/3'} title={'Какие реквизиты в чеке надо проверить'} text={'Кассовый чек, как и любой первичный документ, должен содержать обязательные реквизиты.'}/>
                </Col>
                <Col>
                    <NewsPreview link={'/blog/article/4'} title={'Когда часть обязательных реквизитов в чеке может отсутствовать'} text={'Положения ст. 4.7 предусматривают ситуации, когда в чеке может отсутствовать часть обязательных реквизитов.'}/>
                </Col>
            </Row>
        </Container>
    </section>
  </main>
};

export default Blog;
