import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { MetaTags } from "react-meta-tags";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Products from "../components/Products";
import { getImageURL } from "../helpers/image";
import { getCategory } from "../services/category";

const Category = () => {
  const dispatch = useDispatch();
  let { categoryId } = useParams();

  const [category, setCategory] = useState({
    isLoaded: false,
    error: null,
    category: null,
    products: null,
  });
  console.log(category?.products);
  useEffect(() => {
    // redefine product count from api
    getCategory(categoryId)
      .then(
        (res) =>
          res &&
          setCategory((prev) => ({
            ...prev,
            isLoaded: true,
            category: res?.category,
            products: res?.products,
          }))
      )
      .catch(
        (error) =>
          error && setCategory((prev) => ({ ...prev, isLoaded: true, error }))
      );
  }, [categoryId]);

  return (
    <main>
      <MetaTags>
        <title>
          {process.env.REACT_APP_SITE_NAME + " — " + category?.category?.title}
        </title>
        <meta
          property="title"
          content={
            process.env.REACT_APP_SITE_NAME + " — " + category?.category?.title
          }
        />
        <meta
          property="description"
          content={
            category?.category?.description ??
            process.env.REACT_APP_SITE_DESCRIPTION
          }
        />
        <meta property="og:title" content={process.env.REACT_APP_SITE_NAME} />
        <meta
          property="og:image"
          content={getImageURL(category?.category?.images, "full")}
        />
      </MetaTags>
      <Container>
        <section id={`categorie-${category?.category?.id}`} className="mb-6">
          <h1>{category?.category?.title}</h1>
          <Products products={category.products} />
        </section>
      </Container>
    </main>
  );
};

export default Category;
