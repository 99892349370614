import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import { getProduct } from "../services/product";
import Info from "../components/UI/Info";
import Loader from "../components/UI/Loader";
import { getImageURL } from "../helpers/image";
import { useSelector } from "react-redux";
import { MetaTags } from "react-meta-tags";
import BtnCallbackModal from "../components/utils/BtnCallbackModal";

const Product = () => {
  let { productId } = useParams();
  productId = +productId;
  const cart = useSelector((state) => state?.cart?.items);
  const [product, setProduct] = useState({
    isLoaded: false,
    error: null,
    item: null,
  });

  useEffect(() => {
    const cartItem = cart.find((item) => item?.id === productId);

    // redefine product count from redux
    if (cart && cart?.length && cartItem?.count) {
      setProduct((prev) => ({ ...prev, isLoaded: true, item: cartItem }));
    } else {
      // redefine product count from api
      getProduct({ productId })
        .then(
          (res) =>
            res &&
            setProduct((prev) => ({
              ...prev,
              isLoaded: true,
              item: res?.product,
            }))
        )
        .catch(
          (error) =>
            error && setProduct((prev) => ({ ...prev, isLoaded: true, error }))
        );
    }
  }, [cart, productId]);

  return (
    <main>
      <MetaTags>
        <title>
          {process.env.REACT_APP_SITE_NAME + " — " + product?.item?.title}
        </title>
        <meta
          property="title"
          content={
            process.env.REACT_APP_SITE_NAME + " — " + product?.item?.title
          }
        />
        <meta
          property="description"
          content={
            product?.item?.description ?? process.env.REACT_APP_SITE_DESCRIPTION
          }
        />
        <meta property="og:title" content={process.env.REACT_APP_SITE_NAME} />
        <meta
          property="og:image"
          content={getImageURL(product?.item?.media, "full")}
        />
      </MetaTags>
      <Container>
        {!product?.error ? (
          product?.isLoaded ? (
            product?.item ? (
              <section className="product-full mb-6">
                <Row className="gx-lg-5">
                  <Col md={5} className="mb-4 mb-sm-5 mb-md-0">
                    <figure className="product-full-img">
                      <img
                        src={getImageURL(product?.item?.media, "full")}
                        alt={product?.item?.title}
                      />
                      {product?.new && <figcaption>новинка</figcaption>}
                    </figure>
                  </Col>
                  <Col md={6}>
                    <div className="h-100 d-flex flex-column justify-content-between">
                      <div>
                        <h1>{product?.item?.title}</h1>
                        <Row>
                          <Col xs={12} lg={8}>
                            <h6>Описание</h6>
                            <div>{product?.item?.description}</div>
                          </Col>
                        </Row>
                      </div>
                      <BtnCallbackModal
                        btnClassName={"btn-2"}
                        btnText={"Подробнее"}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            ) : (
              <Info>Продукт не найден</Info>
            )
          ) : (
            <Loader full />
          )
        ) : (
          <Info>Не удалось загрузить продукт</Info>
        )}
      </Container>
    </main>
  );
};

export default Product;
