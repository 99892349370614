const getImageURL = (path = '', size = 'mini', type = 'products') => {

      if (path && path.length > 0) {
            if (size == 'mini') {
                  return process.env.REACT_APP_BASE_URL + '/' + type + path[0].media.mini
            } else {
                  return process.env.REACT_APP_BASE_URL + '/' + type + path[0].media.full
            }
      } else {
            return '/images/no-photo.png'
      }
}

export { getImageURL }
