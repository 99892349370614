import React from 'react';
import { Link } from "react-router-dom";

const NewsPreview = (props) => {
  return (
    <figure className='news-preview'>
      <Link to={props.link}>
        <img src="images/scaled.jpg" alt={props.title} />
        <figcaption>
          <h3>{props.title}</h3>
          <p>{props.text}</p>
        </figcaption>
      </Link>
    </figure>
  );
};

export default NewsPreview;