import React from "react";
import { Link } from "react-router-dom";
import { getImageURL } from "../helpers/image";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BtnCallbackModal from "./utils/BtnCallbackModal";

const ProductItem = ({ product = {} }) => {
  return (
    <div className="product-item">
      <figure>
        <div className="img">
          <LazyLoadImage
            src={getImageURL(product?.media)}
            alt={product?.title}
          />
        </div>

        <figcaption className="d-flex align-items-center justify-content-between">
          <Link
            to={`/product/${product?.id}`}
            className="stretched-link pe-2 fs-09 fw-6"
          >
            {product?.title}
          </Link>
        </figcaption>
        <div className="fs-07 product-desc">{product?.description}</div>
      </figure>

      <div className="info">
        <BtnCallbackModal btnClassName={"btn-2"} btnText={"Подробнее"} />
      </div>
    </div>
  );
};

export default ProductItem;
