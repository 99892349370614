import React from 'react';
import Container from "react-bootstrap/Container";

const Article2 = () => {
  return (
    <main>
      <Container>
        <article className='article'>
          <img src="/images/scaled.jpg" alt="Применение товарных чеков" />
          <h1>Применение товарных чеков</h1>
          <p>Другим видом подтверждения оплаты выступает товарный чек. С его помощью потребитель также сможет защитить свои интересы, однако в отличие от кассового аналога, данный бланк требует особого внимания. Его форма может различаться, а шаблон можно заполнять вручную. Поэтому в случае неправильного оформления он просто превратиться в бесполезную вещь.</p>
          <p>Хотя форма бланка может отличаться, законодательство регламентирует перечень необходимых реквизитов. Среди них необходимо выделить: точное наименование товара, дата покупки, сумма по каждой единице продукции, а также фамилия продавца.</p>
          <p>Правильно оформленный товарный чек поможет не только вернуть товар, но и компенсировать затраты, понесенные в служебных целях. Предоставив его в бухгалтерию, можно вернуть потраченные средства. Поэтому при покупке рекомендуется проверить соответствие оформленного бланка стандартам и требованиям вашей организации.</p>
          <p>Данный документ может также быть использован для отчета в налоговую службу и подтвердить понесенные расходы.</p>
        </article>
      </Container>
    </main>
  );
};

export default Article2;