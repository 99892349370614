import React from 'react';
import Container from "react-bootstrap/Container";

const Article3 = () => {
  return (
    <main>
      <Container>
        <article className='article'>
          <img src="/images/scaled.jpg" alt="Какие реквизиты в чеке надо проверить" />
          <h1>Какие реквизиты в чеке надо проверить</h1>
          <p>Кассовый чек, как и любой первичный документ, должен содержать обязательные реквизиты.</p>
          <p>Если в чеке необходимых реквизитов нет, то расходы по нему нельзя учесть для целей налогообложения. Ведь получается, что документ оформлен с нарушением законодательства.</p>
          <p>Обязательные реквизиты кассового чека перечислены в п. 1 ст. 4.7. закона 54-ФЗ. Этот пункт за последние годы чиновники неоднократно дополняли.</p>
          <p>Важно: до 01.02.2021 для ИП на спецрежимах действовала отсрочка — они могли не указывать в чеке наименование товара (работы, услуги), его количество и цену. Сейчас отсрочка истекла, поэтому чеки, содержащие наименование без детализации (например: «Свободная продажа») — некорректны, и не могут быть приняты к налоговому учету.</p>
          <p>Налоговики регулярно указывают на тот факт, что при отсутствии в чеке обязательных реквизитов, подтвердить им расходы невозможно. Например, в письме Минфина от 05.03.2021 № 03-03-07/158191 чиновники разъясняют, что нельзя учесть расходы по чеку, в котором отсутствует QR-код.</p>
        </article>
      </Container>
    </main>
  );
};

export default Article3;