import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <Container className="h-100">
        <Row xs={2} lg={3} className="w-100 h-100 align-items-center">
          <Col>
            <img
              src="/images/logo2.png"
              alt="чеки"
              className="logo"
            />
          </Col>
          <Col>
            <nav>
              <ul className="list-unstyled d-flex justify-content-end justify-content-lg-center">
                <li>
                  <Link to="/catalog">Каталог</Link>
                </li>
                <li className="ms-3 ms-sm-4 ms-md-5">
                  <Link to="/blog">Статьи</Link>
                </li>
                <li className="ms-3 ms-sm-4 ms-md-5">
                  <Link to="/contacts">Контакты</Link>
                </li>
              </ul>
            </nav>
          </Col>
          <Col className="d-none d-lg-block">
            <ul className="list-unstyled text-end">
              <li><a href="mailto:4eki.kzn@mail.ru">4eki.kzn@mail.ru</a></li>
              <li className="mt-3"><a href="tel:+79600449322">+7 (960) 044-93-22</a></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
