import React from 'react';
import Container from "react-bootstrap/Container";

const Article = () => {
  return (
    <main>
      <Container>
        <article className='article'>
          <img src="/images/scaled.jpg" alt="Название статьи" />
          <h1>Название статьи</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
        </article>
      </Container>
    </main>
  );
};

export default Article;