import React from 'react';
import Container from "react-bootstrap/Container";

const Article1 = () => {
  return (
    <main>
      <Container>
        <article className='article'>
          <img src="/images/scaled.jpg" alt="Кассовые чекии" />
          <h1>Кассовые чеки</h1>
          <p>Первым видом является кассовый чек. Данный фискальный инструмент дает его обладателю уверенность в защите собственных интересов. Его можно использовать для возврата товара низкого качества или в случае других спорных ситуаций, использовать его в суде с целью предъявления претензий недобросовестным производителям. <br/>Учитывая то обстоятельство, что качество бумаги, которая используется в кассовом аппарате, не позволит документу сохраниться длительное время, целесообразным является сделать дополнительную копию.</p>
        </article>
      </Container>
    </main>
  );
};

export default Article1;