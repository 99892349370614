import React from 'react';
import Container from 'react-bootstrap/Container';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import NewsPreview from './NewsPreview';

const NewsSlider = () => {
  return (
    <section className='sec-3 mb-6'>
      <Container>
        <h2>Статьи</h2>
        <Swiper
          className="swiper"
          loop={true}
          modules={[Navigation]}
          spaceBetween={20}
          slidesPerView={1}
          navigation
          breakpoints={{
            576: {
              slidesPerView: 2,
              spaceBetween: 5
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 5
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 20
            },
          }}
        >
          <SwiperSlide>
            <NewsPreview link={'/blog/article/1'} title={'Кассовые чеки'} text={'Первым видом является кассовый чек. Данный фискальный инструмент дает его обладателю уверенность в защите собственных интересов. Его можно использовать для возврата товара низкого качества или в случае других спорных ситуаций, использовать его в суде с целью предъявления претензий недобросовестным производителям.'}/>
          </SwiperSlide>
          <SwiperSlide>
            <NewsPreview link={'/blog/article/2'} title={'Применение товарных чеков'} text={'Другим видом подтверждения оплаты выступает товарный чек. С его помощью потребитель также сможет защитить свои интересы, однако в отличие от кассового аналога, данный бланк требует особого внимания. Его форма может различаться, а шаблон можно заполнять вручную. Поэтому в случае неправильного оформления он просто превратиться в бесполезную вещь.'}/>
          </SwiperSlide>
          <SwiperSlide>
            <NewsPreview link={'/blog/article/3'} title={'Какие реквизиты в чеке надо проверить'} text={'Кассовый чек, как и любой первичный документ, должен содержать обязательные реквизиты.'}/>
          </SwiperSlide>
          <SwiperSlide>
            <NewsPreview link={'/blog/article/4'} title={'Когда часть обязательных реквизитов в чеке может отсутствовать'} text={'Положения ст. 4.7 предусматривают ситуации, когда в чеке может отсутствовать часть обязательных реквизитов.'}/>
          </SwiperSlide>
        </Swiper>
      </Container>
    </section>
  );
};

export default NewsSlider;