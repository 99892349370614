import React from "react";
import Col from "react-bootstrap/Col";
import Category from "./Category";
import CategoryCard from "./CategoryCard";

const Categories = ({ categories = [] }) => {
  return (
    categories?.length > 0 &&
    categories.map((item, index) => (
      // <Category key={item?.category?.id} category={item?.category} products={item?.products} />
      <Col>
        <CategoryCard key={index} category={item} />
      </Col>
    ))
  );
};

export default Categories;
