import React from "react";
import { Link } from "react-router-dom";

const CategoryCard = ({ category }) => {
  return (
    <Link to={`/category/${category?.id}`}>
      {/* <figure className="category-card" id={`categorie-${category?.id}`}>
        <img src="/images/c55.jpg" alt={category?.title} />
        <figcaption>{category?.title}</figcaption>
      </figure> */}
      <div className="category-item">
        <h5>{category?.title}</h5>
      </div>
    </Link>
  );
};

export default CategoryCard;
