import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import Home from "../pages/Home";
import Product from "../pages/Product";
import Blog from "../pages/Blog";
import About from "../pages/About";
import Category from "../pages/Category";
import NotFound from "../pages/NotFound";
import AuthRoute from "../layouts/AuthRoute";
import AdminRounter from "./AdminRouter";
import Error from "../pages/Error";
import Loader from "../components/UI/Loader";
import Article from "../pages/Article";
import Catalog from "../pages/Catalog";
import Article1 from "../pages/Article1";
import Article2 from "../pages/Article2";
import Article3 from "../pages/Article3";
import Article4 from "../pages/Article4";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<AppLayout />}
      errorElement={<Error />}
      loader={() => <Loader full />}
    >
      <Route index element={<Home />} />
      <Route path="product" element={<Product />}>
        <Route path=":productId" element={<Product />} />
      </Route>
      <Route path="catalog" element={<Catalog/>} />
      <Route path="category" element={<Category />}>
        <Route path=":categoryId" element={<Category />} />
      </Route>
      <Route path="blog" element={<Blog />} />
      <Route path="blog/article" element={<Article/>} />
      <Route path="blog/article/1" element={<Article1/>} />
      <Route path="blog/article/2" element={<Article2/>} />
      <Route path="blog/article/3" element={<Article3/>} />
      <Route path="blog/article/4" element={<Article4/>} />
      <Route path="contacts" element={<About />} />
      <Route
        path="admin/*"
        element={
          <AuthRoute admin>
            <AdminRounter />
          </AuthRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
