import React from "react";
import CategoriesContainer from "../components/containers/CategoriesContainer";
import Loader from "../components/UI/Loader";
import { useGetCategoriesQuery } from "../services/RTK/home";

const Catalog = () => {
  const categories = useGetCategoriesQuery();

  return (
    <main>
      {categories.isLoading && <Loader full />}

      {!categories.error && categories?.data?.categories?.length > 0 && (
        <CategoriesContainer categories={categories.data.categories} />
      )}
    </main>
  );
};

export default Catalog;
