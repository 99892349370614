import React from 'react';
import Container from "react-bootstrap/Container";

const Article4 = () => {
  return (
    <main>
      <Container>
        <article className='article'>
          <img src="/images/scaled.jpg" alt="Когда часть обязательных реквизитов в чеке может отсутствовать" />
          <h1>Когда часть обязательных реквизитов в чеке может отсутствовать</h1>
          <p>Положения ст. 4.7 предусматривают ситуации, когда в чеке может отсутствовать часть обязательных реквизитов.</p>
          <p>Так, например, платежным агентам разрешается не указывать в чеке ставку и размер НДС (п. 3 ст. 4.7).</p>
          <p>А пользователям, использующим кассовую технику в отдаленных местностях, можно некоторые реквизиты не указывать в чеке (п. 2 ст. 4.7):</p>
          <p>На бумажном чеке может отсутствовать часть реквизитов, обязательная для электронного. Состав реквизитов для разных форм чека ФНС утвердила в приказе от 14.09.2020 № ЕД-7-20/662@.</p>
          <p>Например, реквизиты «номер смены», и «номер чека за смену» обязательны только для электронного чека (причем только для форматов фискальных документов начиная с версии 1.05).</p>
          <p>Адрес сайта ФНС нужно включать в чек при передаче документа в ОФД электронным способом (если ранее его оператору не передавали). А для печатной формы чека этот реквизит не обязателен (письмо ФНС от 02.04.2021 № АБ-4-20/4418).</p>
        </article>
      </Container>
    </main>
  );
};

export default Article4;