import React from "react";
import Container from "react-bootstrap/Container";
import { BiMailSend, BiMobileAlt } from "react-icons/bi";
import { SiWhatsapp, SiTelegram } from "react-icons/si";
import { MetaTags } from "react-meta-tags";

const About = () => {
  return <main>
    <MetaTags>
      <title>{process.env.REACT_APP_SITE_NAME} — О нас</title>
      <meta
        property="title"
        content={process.env.REACT_APP_SITE_NAME + " — О нас"}
      />
      <meta
        property="description"
        content={`В ${process.env.REACT_APP_SITENAME} каждый ролл приготовлен с заботой о ваших гастрономических предпочтениях`}
      />
      <meta
        property="og:title"
        content={process.env.REACT_APP_SITE_NAME + " — О нас"}
      />
    </MetaTags>
    <Container>
      <section className="about mb-6">
        <h1 className="main-color text-center">Контакты</h1>
        <div className="d-flex justify-content-center mb-5">
          <ul className="list-unstyled">
            <li>
              <h2>
                <a href="mailto:4eki.kzn@mail.ru" className="d-flex align-items-center" style={{fontSize:'22px'}}>
                  <BiMailSend className="fs-15 main-color me-3" />
                  <span>4eki.kzn@mail.ru</span>
                </a>
              </h2>
            </li>
            <li className="mt-3">
              <h2>
                <a href="tel:+79600449322" className="d-flex align-items-center" style={{fontSize:'22px'}}>
                  <BiMobileAlt className="fs-15 main-color me-3"/>
                  <span>+7 (960) 044-93-22</span>
                </a>
              </h2>
            </li>
            <li className="mt-3">
              <h2>
                <a href="https://t.me/Dokikzn"  className="d-flex align-items-center" style={{fontSize:'22px'}}>
                  <SiTelegram className="fs-15 main-color me-3"/>
                  <span>Telegram</span>
                </a>
              </h2>
            </li>
            <li className="mt-3">
              <h2>
                <a href="https://api.whatsapp.com/send?phone=79600449322" className="d-flex align-items-center" style={{fontSize:'22px'}}>
                  <SiWhatsapp className="fs-15 main-color me-3"/>
                  <span>Whatsapp</span>
                </a>
              </h2>
            </li>
          </ul>
        </div>
      </section>
    </Container>
  </main>
};

export default About;
